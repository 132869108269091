import {
    buildCollection,
    buildProperty,
    buildSchema,
    EntityReference,
    PropertiesOrBuilder,
  } from "@camberi/firecms";
  export type lawDays = {
    nombre: string;
    fecha:Date;
  };  

  const Lawdays: PropertiesOrBuilder<lawDays> = {
    nombre: {
      validation: { required: true },
      title: "Nombre",
      dataType: "string",
    },
    fecha: {
      validation: { required: true },
      title: "Fecha",
      dataType: "timestamp",
    }
  }
  export const LawDays = buildSchema<lawDays>({
    name: "Días de Ley",
    defaultValues: {
      creationDate: new Date(),
      last_update: new Date()
    },
    properties: Lawdays,
  });
  export const buildLawDays = buildCollection({
    defaultSize: "xs",
    schema: LawDays,
    path: "diasLey",
    name: "Días de ley",
    inlineEditing: false,
    properties: [
      "nombre",
      "fecha",
    ],
    group: "RH",
    exportable: true,
    pagination: true,
    permissions: ({ authController }) => ({
      edit:
        authController.extra.roles.includes("admin") ||
        authController.extra.roles.includes("rrhh"),
      create:
        authController.extra.roles.includes("admin") ||
        authController.extra.roles.includes("rrhh"),
      delete:
        authController.extra.roles.includes("admin") ||
        authController.extra.roles.includes("rrhh"),
    }),
  });
  