import {
  buildCollection,
  buildSchema,
  EntityReference,
  PropertiesOrBuilder,
} from "@camberi/firecms";

export type Direciones = {
  name: string;
  creationDate: Date;
  jobs?: EntityReference[];
};

const directionProperties: PropertiesOrBuilder<Direciones> = {
  creationDate: {
    dataType: "timestamp",
    disabled: {
      hidden: true,
    },
    validation: {
      required: true,
    },
  },
  name: {
    title: "Dirección",
    validation: { required: true },
    dataType: "string",
  },
};

export const directionSchema = buildSchema<Direciones>({
  name: "Direciones de la empresa",
  defaultValues: {
    creationDate: new Date(),
  },
  properties: directionProperties,
});

export const buildDirection = buildCollection({
  path: "directions-company",
  defaultSize: "xs",
  schema: directionSchema,
  name: "Direciones de la empresa",
  inlineEditing: false,
  group: "RH",
  exportable: false,
  pagination: true,
  permissions: ({ authController }) => ({
    edit:
      authController.extra.roles.includes("admin") ||
      authController.extra.roles.includes("rrhh"),
    create:
      authController.extra.roles.includes("admin") ||
      authController.extra.roles.includes("rrhh"),
    delete:
      authController.extra.roles.includes("admin") ||
      authController.extra.roles.includes("rrhh"),
  }),
});
