import {
  buildCollection,
  buildSchema,
  PropertiesOrBuilder,
} from "@camberi/firecms";

export type interests = {
  name: string;
  creationDate: Date;
};

const interestsProperties: PropertiesOrBuilder<interests> = {
  name: {
    title: "Nombre",
    validation: { required: true },
    dataType: "string",
    columnWidth: 250,
  },
  creationDate: {
    dataType: "timestamp",
    disabled: {
      hidden: true,
    },
    validation: {
      required: true,
    },
  },
};

export const interestsSchema = buildSchema<interests>({
  name: "Intereses",
  defaultValues: {
    creationDate: new Date(),
  },
  properties: interestsProperties,
});

export const buildinterests = buildCollection({
  path: "interests",
  defaultSize: "xs",
  schema: interestsSchema,
  name: "Intereses",
  inlineEditing: false,
  properties: ["name"],
  group: "RH",
  exportable: false,
  pagination: true,
  permissions: ({ authController }) => ({
    edit:
      authController.extra.roles.includes("admin") ||
      authController.extra.roles.includes("rrhh"),
    create:
      authController.extra.roles.includes("admin") ||
      authController.extra.roles.includes("rrhh"),
    delete:
      authController.extra.roles.includes("admin") ||
      authController.extra.roles.includes("rrhh"),
  }),
});
