import {
    buildCollection,
    buildSchema,
    PropertiesOrBuilder,
  } from "@camberi/firecms";
  
  export type Contract = {
    contractType: string;    
    contractPerdiod:boolean;
    amountExcludingVAT: boolean;
    paymentPeriod:boolean;
    paymentMethod:boolean;
    paymentDate:boolean
  };
  
  const contractProperties: PropertiesOrBuilder<Contract> = {
    contractType: {
      validation: { required: true },
      title: "Tipo de Contrato",
      dataType: "string",
    },
    contractPerdiod: {
      validation: { required: true },
      title: "Vigencia del Contrato",
      dataType: "boolean",
    },
    amountExcludingVAT: {
      validation: { required: true },
      title: "Monto de Contraprestación sin IVA",
      dataType: "boolean",
    },
    paymentPeriod: {
      validation: { required: true },
      title: "Forma de Pago",
      dataType: "boolean",
    },
    paymentMethod: {
      validation: { required: true },
      title: "Método de Pago",
      dataType: "boolean",
    },
    paymentDate: {
      validation: { required: true },
      title: "Fecha de Pago",
      dataType: "boolean",
    },
  };
  
  export const contractSchema = buildSchema<Contract>({
    name: "Campos por tipo de contrato",
    defaultValues: {
      contractType: "",      
      contractValidity: false,
      amountExcludingVAT: false,
      paymentPeriod:false,
      paymentMethod:false,
      paymentDate:false,
    },
    properties: contractProperties,
  });
  
  export const buildContractCollection = buildCollection({
    defaultSize: "xs",
    schema: contractSchema,
    path: "contracts",
    name: "Campos por tipo de contrato",
    inlineEditing: false,
    properties: [
      "contractType",      
      "contractValidity",
      "amountExcludingVAT",
      "paymentPeriod",
      "paymentMethod",
      "paymentDate",
    ],
    group: "Legal",
    exportable: true,
    pagination: true,
    permissions: ({ authController }) => ({
        edit:
          authController.extra.roles.includes("admin") ||
          authController.extra.roles.includes("rrhh"),
        create:
          authController.extra.roles.includes("admin") ||
          authController.extra.roles.includes("rrhh"),
        delete:
          authController.extra.roles.includes("admin") ||
          authController.extra.roles.includes("rrhh"),
      }),
  });
  