import {
  buildCollection,
  buildEntityCallbacks,
  buildProperty,
  buildSchema,
  EntityOnSaveProps,
  EntityReference,
  PropertiesOrBuilder,
} from "@camberi/firecms";

export type Post = {
  featureImage: string;
  status: string;
  title: string;
  description: string;
  body: string;
  tags: string[];
  categories: EntityReference[];
  creationDate: Date;
  modifiedDate: Date;
  published: boolean;
  publisher: {
    name: string;
    external_id: string;
  };
  showHome: boolean;
};

const postProperties: PropertiesOrBuilder<Post> = {
  creationDate: {
    dataType: "timestamp",
    disabled: {
      hidden: true,
    },
    validation: {
      required: true,
    },
  },
  modifiedDate: {
    dataType: "timestamp",
    disabled: {
      hidden: true,
    },
  },
  title: {
    title: "Título",
    validation: { required: true },
    dataType: "string",
  },
  description: {
    title: "Descripción",
    validation: { required: false },
    dataType: "string",
    config: {
      multiline: true,
      markdown: true,
    },
  },
  featureImage: ({ values }) =>
    buildProperty({
      title: "Imagen",
      dataType: "string",
      config: {
        storageMeta: {
          mediaType: "image",
          storagePath: "images-banner/" + values.title,
          acceptedFiles: ["image/*"],
          storeUrl: true,
        },
      },
    }),
  body: {
    title: "Contenido",
    validation: { required: false },
    dataType: "string",
    config: {
      markdown: true,
    },
  },
  tags: {
    title: "Tags",
    description: "Tags de la entrada",
    validation: { required: true },
    dataType: "array",
    of: {
      dataType: "string",
    },
  },
  categories: {
    dataType: "array",
    title: "Categorías",
    validation: {
      required: true,
    },
    description: "Selecciona las categorías a las que pertenece ésta entrada",
    of: {
      dataType: "reference",
      path: "categories",
      previewProperties: ["name"],
    },
  },
  status: {
    title: "Estatus",
    validation: { required: true },
    dataType: "string",
    config: {
      enumValues: {
        new: "Nuevo",
        inReview: "En revisión",
        approved: "Aprobado",
      },
    },
  },
  published: ({ values }) =>
    buildProperty({
      title: "Publicar",
      dataType: "boolean",
      columnWidth: 100,
      disabled:
        values.status === "approved"
          ? false
          : {
              clearOnDisabled: true,
              disabledMessage:
                "El estado debe ser aprobado para habilitar esta bandera",
            },
    }),
  showHome: ({ values }) =>
    buildProperty({
      title: "Ver en Inicio",
      dataType: "boolean",
      columnWidth: 100,
      disabled:
        values.published === true
          ? false
          : {
              clearOnDisabled: true,
              disabledMessage: "La nota debe estar publicada",
            },
    }),
  publisher: {
    title: "Autor",
    dataType: "map",
    properties: {
      name: {
        title: "Name",
        dataType: "string",
      },
      external_id: {
        title: "External id",
        dataType: "string",
      },
    },
  },
};

export const postSchema = buildSchema<Post>({
  name: "Publicaciones Noticias",
  defaultValues: {
    creationDate: new Date(),
    status: "new",
  },
  properties: postProperties,
});

export const postsCallbacks = buildEntityCallbacks({
  onPreSave: ({ values }) => {
    values.modifiedDate = new Date();
    return values;
  },
  onSaveSuccess: (props: EntityOnSaveProps<Post>) => {
    //console.log("onSaveSuccess", props);
  },
});

export const buildPost = buildCollection({
  path: "posts",
  defaultSize: "xs",
  schema: postSchema,
  name: "Publicaciones Noticias",

  inlineEditing: false,
  properties: [
    "title",
    "status",
    "description",
    "creationDate",
    "tags",
    "categories",
    "published",
    "publisher",
  ],
  group: "Publicaciones",
  exportable: false,
  pagination: true,
  permissions: ({ authController }) => ({
    edit:
      authController.extra.roles.includes("admin") ||
      authController.extra.roles.includes("rrhh") ||
      authController.extra.roles.includes("controller") ||
      authController.extra.roles.includes(" branch_management"),
    create:
      authController.extra.roles.includes("admin") ||
      authController.extra.roles.includes("rrhh") ||
      authController.extra.roles.includes("controller") ||
      authController.extra.roles.includes(" branch_management"),
    delete:
      authController.extra.roles.includes("admin") ||
      authController.extra.roles.includes("rrhh") ||
      authController.extra.roles.includes("controller") ||
      authController.extra.roles.includes(" branch_management"),
  }),
  callbacks: postsCallbacks,
});
