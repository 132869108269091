import {
  buildCollection,
  buildProperty,
  buildSchema,
  EntityReference,
  PropertiesOrBuilder,
} from "@camberi/firecms";

export type Distritos = {
  name: string;
  creationDate: Date;
  distrital?: EntityReference;
};

const distritosProperties: PropertiesOrBuilder<Distritos> = {
  creationDate: {
    dataType: "timestamp",
    disabled: {
      hidden: true,
    },
    validation: {
      required: true,
    },
  },
  name: {
    title: "Distrito",
    validation: { required: true },
    dataType: "string",
    columnWidth: 250,
  },
  distrital: buildProperty({
    title: "Distrital",
    dataType: "reference",
    path: "users",
    previewProperties: ["name", "lastname"],
  }),
};

export const distritosSchema = buildSchema<Distritos>({
  name: "Publicaciones Noticias",
  defaultValues: {
    creationDate: new Date(),
  },
  properties: distritosProperties,
});

export const buildDistritos = buildCollection({
  path: "distritos",
  defaultSize: "xs",
  schema: distritosSchema,
  name: "Distritos",
  inlineEditing: false,
  group: "Sucursales",
  exportable: false,
  pagination: true,
  permissions: ({ authController }) => ({
    edit:
      authController.extra.roles.includes("admin") ||
      authController.extra.roles.includes("rrhh") ||
      authController.extra.roles.includes("controller"),
    create:
      authController.extra.roles.includes("admin") ||
      authController.extra.roles.includes("rrhh") ||
      authController.extra.roles.includes("controller"),
    delete:
      authController.extra.roles.includes("admin") ||
      authController.extra.roles.includes("rrhh") ||
      authController.extra.roles.includes("controller"),
  }),
});
