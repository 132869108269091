import {
    buildCollection,
    buildSchema,
    PropertiesOrBuilder,
  } from "@camberi/firecms";
  
  export type Document = {
    documentType: string;
    legalPerson: boolean;
    individualPerson: boolean;
  };
  
  const documentProperties: PropertiesOrBuilder<Document> = {
    documentType: {
      validation: { required: true },
      title: "Documento",
      dataType: "string",   
    },
    legalPerson: {
      validation: { required: false },
      title: "Persona Moral",
      dataType: "boolean",
    },
    individualPerson: {
      validation: { required: false },
      title: "Persona Fisica",
      dataType: "boolean",
    },
  };
  
  export const documentSchema = buildSchema<Document>({
    name: "Documents Legal",
    defaultValues: {
      documentType: "",
      legalPerson: false,
      individualPerson: false,
    },
    properties: documentProperties,
  });
  
  export const buildDocumentCollection = buildCollection({
    defaultSize: "xs",
    schema: documentSchema,
    path: "documents",
    name: "Documentos Legal",
    inlineEditing: false,
    properties: ["documentType", "legalPerson", "individualPerson"],
    group: "Legal",
    exportable: true,
    pagination: true,
    permissions: ({ authController }) => ({
        edit:
          authController.extra.roles.includes("admin") ||
          authController.extra.roles.includes("rrhh"),
        create:
          authController.extra.roles.includes("admin") ||
          authController.extra.roles.includes("rrhh"),
        delete:
          authController.extra.roles.includes("admin") ||
          authController.extra.roles.includes("rrhh"),
      }),
  });
  