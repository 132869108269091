import {
    buildCollection,
    buildProperty,
    buildSchema,
    EntityReference,
    PropertiesOrBuilder,
  } from "@camberi/firecms";
  
  export type lawVacation = {
    nombre: string;
    years: { [key: string]: string }[];

  };

  const leyProperties: PropertiesOrBuilder<lawVacation> = {
    nombre: {
      validation: { required: true },
      title: "Nombre",
      dataType: "string",
    },
    years: {
      validation: { required: true },
      title: "Años",
      dataType: "array",
      of: {
        dataType: "map",
        description: "Selecciona los puestos",
        properties: {
          years: {
              title: "Año años",
              description: "Sigue el formato CORRECTO si es un rango '5-8'(De 5 a 8 años) si es un solo año '1' (1 año)",
              validation: { required: true },
              dataType: "string"
          },
          days: {
              title: "Días correspondientes",
              description: "Dias correspondientes",
              validation: { required: true, uniqueInArray: true },
              dataType: "number",
        
          }
      }

      },
    },
  
  }
  
export const lawVacationSchema = buildSchema<lawVacation>({
  name: "vacaciones Ley",
  defaultValues: {
    creationDate: new Date(),
    last_update: new Date()
  },
  properties: leyProperties,
});

export const buildVacation = buildCollection({
  defaultSize: "xs",
  schema: lawVacationSchema,
  path: "vacation",
  name: "Vacaciones",
  inlineEditing: false,
  properties: [
    "nombre",
    "years"
  ],
  group: "RH",
  exportable: true,
  pagination: true,
  permissions: ({ authController }) => ({
    edit:
      authController.extra.roles.includes("admin") ||
      authController.extra.roles.includes("rrhh"),
    create:
      authController.extra.roles.includes("admin") ||
      authController.extra.roles.includes("rrhh"),
    delete:
      authController.extra.roles.includes("admin") ||
      authController.extra.roles.includes("rrhh"),
  }),
});
