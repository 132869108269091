import {
    buildCollection,
    buildSchema,
    PropertiesOrBuilder,
  } from "@camberi/firecms";
  export type daysAdvance = {
    nombre: string;
    dias:number;
  };  

  const diasAnticipacion: PropertiesOrBuilder<daysAdvance> = {
    nombre: {
      validation: { required: true },
      title: "Nombre",
      dataType: "string",
    },
    dias:{
      validation:{ required:true },
      title:"Días de anticipación",
      dataType:"number"
    }

  }
  export const diasA = buildSchema<daysAdvance>({
    name: "Días de anticipación",
    defaultValues: {
      creationDate: new Date(),
      last_update: new Date(),
      limite:1,
    },
    properties: diasAnticipacion,
  });
  export const buildAnticipacion = buildCollection({
    defaultSize: "xs",
    schema: diasA,
    path: "diasAnticipacion",
    name: "Días de anticipación a solicitud",
    inlineEditing: false,
    properties: [
      "nombre",
      "dias"
    ],
    group: "RH",
    exportable: true,
    pagination: true,
    permissions: ({ authController }) => ({
      edit:
        authController.extra.roles.includes("admin") ||
        authController.extra.roles.includes("rrhh"),
      create:
        authController.extra.roles.includes("admin") ||
        authController.extra.roles.includes("rrhh"),
      delete:
        authController.extra.roles.includes("admin") ||
        authController.extra.roles.includes("rrhh"),
    }),
  });
  