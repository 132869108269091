import {
  buildCollection,
  buildEntityCallbacks,
  buildProperty,
  buildSchema,
  EntityOnSaveProps,
  EntityReference,
  PropertiesOrBuilder,
} from "@camberi/firecms";

export type Workspaces = {
  name: string;
  creationDate: Date;
  creator: EntityReference;
  members: EntityReference[];
  description: string;
  banner: string;
  profile: string;
};

const workspacesProperties: PropertiesOrBuilder<Workspaces> = {
  creationDate: {
    dataType: "timestamp",
    disabled: {
      hidden: true,
    },
    validation: {
      required: true,
    },
  },
  creator: buildProperty({
    title: "Administrador del area",
    dataType: "reference",
    validation: { required: true },
    path: "users",
    previewProperties: ["name", "lastname"],
  }),
  name: {
    title: "Nombre del area",
    validation: { required: true },
    dataType: "string",
    columnWidth: 250,
  },
  description: {
    title: "Descripcion breve del area de trabajo",
    validation: { required: true },

    dataType: "string",
    columnWidth: 250,
  },
  banner: ({ values }) =>
    buildProperty({
      title: "Banner para el area de trabajo",
      dataType: "string",
      validation: { required: true },
      config: {
        storageMeta: {
          mediaType: "image",
          storagePath: "workspaces-images/banners/" + values.name,

          acceptedFiles: ["image/*"],
          storeUrl: true,
        },
      },
    }),
  profile: ({ values }) =>
    buildProperty({
      title: "Perfil del area de trabajo",
      validation: { required: true },
      description: "Por favor sube una imagen con formato cuadrado",
      dataType: "string",
      config: {
        storageMeta: {
          mediaType: "image",
          storagePath: "workspaces-images/profiles/" + values.name,
          acceptedFiles: ["image/*"],
          storeUrl: true,
        },
      },
    }),
  members: {
    title: "Miembros del equipo",
    validation: { required: true },
    dataType: "array",
    of: {
      dataType: "reference",
      description: "Selecciona a los usuarios que quieres agregar",
      path: "users",
      previewProperties: ["name", "lastname", "email"],
    },
  },
};

export const workspacesSchema = buildSchema<Workspaces>({
  name: "Areas de trabajo",
  defaultValues: {
    creationDate: new Date(),
  },
  properties: workspacesProperties,
});

export const workspacesCallbacks = buildEntityCallbacks({
  onPreSave: ({ values }) => {
    values.members.push(values.creator);
    return values;
  },
  onSaveSuccess: (props: EntityOnSaveProps<Workspaces>) => {
    //console.log("onSaveSuccess", props);
  },
});

export const buildWorkspaces = buildCollection({
  path: "work-spaces",
  defaultSize: "xs",
  schema: workspacesSchema,
  name: "Areas de trabajo",
  inlineEditing: false,
  group: "Compañia",
  exportable: false,
  pagination: true,
  permissions: ({ authController }) => ({
    edit: authController.extra.roles.includes("admin"),
    create: authController.extra.roles.includes("admin"),
    delete: authController.extra.roles.includes("admin"),
  }),
  callbacks: workspacesCallbacks,
});
