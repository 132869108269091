import {
  buildCollection,
  buildEntityCallbacks,
  buildProperty,
  buildSchema,
  EntityOnSaveProps,
  EntityReference,
  PropertiesOrBuilder,
} from "@camberi/firecms";

export type HistoryUs = {
  parte1: string;
  parte2: string;
  img1: string;
  img2: string;
};

const historyProperties: PropertiesOrBuilder<HistoryUs> = {
  parte1: {
    title: "Parte 1",
    validation: { required: false },
    dataType: "string",
    config: {
      multiline: true,
      markdown: true,
      previewAsTag: true,
    },
  },
  parte2: {
    title: "Parte 2",
    validation: { required: false },
    dataType: "string",
    config: {
      multiline: true,
      markdown: true,
      previewAsTag: true,
    },
  },
  img1: buildProperty({
    title: "Imagen 1",
    dataType: "string",
    config: {
      storageMeta: {
        mediaType: "image",
        storagePath: "company-info",
        acceptedFiles: ["image/*"],
        storeUrl: true,
      },
    },
  }),
  img2: buildProperty({
    title: "Imagen 2",
    dataType: "string",
    config: {
      storageMeta: {
        mediaType: "image",
        storagePath: "company-info",
        acceptedFiles: ["image/*"],
        storeUrl: true,
      },
    },
  }),
};

export const historySchema = buildSchema<HistoryUs>({
  name: "Historia de la compañia",
  properties: historyProperties,
});

export const buildhistory = buildCollection({
  path: "history",
  defaultSize: "xs",
  schema: historySchema,
  name: "Historia de la compañia",
  inlineEditing: false,
  properties: ["parte1", "parte2", "img1", "img2"],
  group: "Compañia",
  exportable: false,
  pagination: true,
  permissions: ({ authController }) => ({
    edit:
      authController.extra.roles.includes("admin") ||
      authController.extra.roles.includes("rrhh"),
    create:
      authController.extra.roles.includes("admin") ||
      authController.extra.roles.includes("rrhh"),
    delete:
      authController.extra.roles.includes("admin") ||
      authController.extra.roles.includes("rrhh"),
  }),
});
