import { EmailAuthProvider, User as FirebaseUser } from "firebase/auth";
import {
  Authenticator,
  FirebaseCMSApp,
  NavigationBuilder,
  NavigationBuilderProps,
} from "@camberi/firecms";

import "typeface-rubik";
import "typeface-space-mono";
import { categoryPost } from "./schemas/category.schema";
import { buildPost } from "./schemas/posts.schema";
import { buildrecognitions } from "./schemas/recognitionsSchema";
import { buildinterests } from "./schemas/interestsSchema";
import { buildhistory } from "./schemas/history.schema";
import { buildPolicies } from "./schemas/policies.schema";
import logo from "./logo.png";
import { buildPlan } from "./schemas/planes.schema";
import { buildDates } from "./schemas/datesImportant.schema";
import { buildJobs } from "./schemas/job.schema";
import { buildAreas } from "./schemas/areas.schema";

import { buildQuestions } from "./schemas/questions.schema";
import { buildBrands } from "./schemas/brands.schema";
import { buildDistritos } from "./schemas/ditritos.schema";
import { buildWorkspaces } from "./schemas/workspaces.schema";
import { buildUser, userSchema } from "./schemas/user.schema";
import { buildAdvertisement } from "./schemas/advertisement.schema";
import { buildbranch } from "./schemas/brancOfficeSchema";
import { buildCompany } from "./schemas/company.schema";
import { buildFiles } from "./schemas/files.schema";
import { buildLinks } from "./schemas/linksDirectory";
import { buildDirection } from "./schemas/directions.schema";
import { buildsalesforce } from "./schemas/salesforcecat.schema";
import { buildVacation } from "./schemas/lawVacation.schema";
import { buildDiasUStorage } from "./schemas/diasUStorage.schema";
import { buildLawDays } from "./schemas/lawDays";
import { buildAnticipacion } from "./schemas/daysInAdvanceRequest";
import { buildContractCollection } from "./schemas/legal.shcema";
import { buildDocumentCollection } from "./schemas/documents.schema";
import { buildCatalogSocietiesCollection } from "./schemas/catalogSocieties.chema";

const DEFAULT_SIGN_IN_OPTIONS = [EmailAuthProvider.PROVIDER_ID];

// const firebaseConfig = {
//   apiKey: "AIzaSyAZCAJiYnCf17mqsrSQE8d3u7Q0QYUXhzs",
//   authDomain: "ustorageintranet-dev.firebaseapp.com",
//   projectId: "ustorageintranet-dev",
//   storageBucket: "ustorageintranet-dev.appspot.com",
//   messagingSenderId: "163682187850",
//   appId: "1:163682187850:web:2f64c9274387a3c26904b3",
// };


const firebaseConfig = {
  apiKey: "AIzaSyD5zGLBhJEWteeQmJjT7B5gEE612UC8__I",
  authDomain: "ustorageintranet.firebaseapp.com",
  projectId: "ustorageintranet",
  storageBucket: "ustorageintranet.appspot.com",
  messagingSenderId: "852353990448",
  appId: "1:852353990448:web:ec0b7b0632bb79558ad2f5",
  measurementId: "G-T7Z0DXCYSE",
};

export default function App() {
  const navigation: NavigationBuilder = async ({
    user,
    authController,
  }: NavigationBuilderProps) => {
    console.log("Groups");
    return {
      collections: [
        buildUser,
        buildPost,
        buildAdvertisement,
        categoryPost,
        buildbranch,
        buildinterests,
        buildrecognitions,
        buildCompany,
        buildhistory,
        buildPolicies,
        buildFiles,
        buildPlan,
        buildDates,
        buildJobs,
        buildAreas,
        buildsalesforce,
        buildDirection,
        buildQuestions,
        buildBrands,
        buildDistritos,
        buildWorkspaces,
        buildLinks,
        buildVacation,
        buildDiasUStorage,
        buildLawDays,
        buildAnticipacion,
        buildContractCollection,
        buildDocumentCollection,
        buildCatalogSocietiesCollection
      ],
    };
  };

  const myAuthenticator: Authenticator<FirebaseUser> = async ({
    user,
    authController,
    dataSource,
  }) => {
    const resp = await dataSource.fetchEntity({
      path: "users",
      entityId: user.uid,
      schema: userSchema,
    });

    // You can throw an error to display a message
    if (
      resp.values.role.includes("branch_employee") ||
      resp.values.role.includes("corporate_employee") ||
      resp.values.role.includes("branch_employee_limit")
    ) {
      throw Error("No access");
    }
    // This is an example of retrieving async data related to the user
    // and storing it in the user extra field.
    const sampleUserData = await Promise.resolve({
      roles: [resp.values.role],
    });
    authController.setExtra(sampleUserData);
    return true;
    // You can throw an error to display a message
    // if (user?.email?.includes("flanders")) {
    //   throw Error("Stupid Flanders!");
    // }

    // /*  const resp = await dataSource.fetchEntity({ path:'users', entityId: user.uid, schema: userSchema })
    //     console.log('respuesta auth', resp); */

    // //Conditions

    // console.log("Allowing access to", user?.email);
    // // This is an example of retrieving async data related to the user
    // // and storing it in the user extra field.
    // const sampleUserData = await Promise.resolve({
    //   // use when const resp exist
    //   // roles: [resp.values.role]
    //   roles: ["admin"],
    // });
    // authController.setExtra(sampleUserData);
    // return true;
  };

  return (
    <FirebaseCMSApp
      name={"UStorage Intranet"}
      authentication={myAuthenticator}
      navigation={navigation}
      signInOptions={[
        {
          provider: EmailAuthProvider.PROVIDER_ID,
          disableSignUp: { status: true },
        },
      ]}
      firebaseConfig={firebaseConfig}
      primaryColor={"#490c66"}
      secondaryColor={"#ef7521"}
      logo={logo}
    />
  );
}
