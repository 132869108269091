import {
  buildCollection,
  buildProperty,
  buildSchema,
  EntityReference,
  PropertiesOrBuilder,
} from "@camberi/firecms";

export type Questions = {
  question: string;
  request: string;
  area: EntityReference[];
  creationDate: Date;
  modifiedDate: Date;
  status: string;
  published: boolean;
};

const questionsProperties: PropertiesOrBuilder<Questions> = {
  creationDate: {
    dataType: "timestamp",
    disabled: {
      hidden: true,
    },
    validation: {
      required: true,
    },
  },
  modifiedDate: {
    dataType: "timestamp",
    disabled: {
      hidden: true,
    },
  },
  question: {
    title: "Pregunta",
    validation: { required: true },
    dataType: "string",
    columnWidth: 250,
  },
  request: {
    title: "Respuesta",
    validation: { required: false },
    dataType: "string",
    config: {
      multiline: true,
      markdown: true,
    },
  },
  status: {
    title: "Status",
    validation: { required: true },
    dataType: "string",
    config: {
      enumValues: {
        new: "Nuevo",
        inReview: "En revisión",
        approved: "Aprobado",
      },
    },
  },
  area: {
    title: "Areas",
    dataType: "array",
    of: {
      dataType: "reference",
      description: "Selecciona las areas",
      path: "areas-company",
      previewProperties: ["name"],
    },
  },
  published: ({ values }) =>
    buildProperty({
      title: "Published",
      dataType: "boolean",
      columnWidth: 100,
      disabled:
        values.status === "approved"
          ? false
          : {
              clearOnDisabled: true,
              disabledMessage:
                "Status must be approved in order to enable this the published flag",
            },
    }),
};

export const questionsSchema = buildSchema<Questions>({
  name: "Preguntas frecuentes",
  defaultValues: {
    creationDate: new Date(),
    status: "new",
  },
  properties: questionsProperties,
});

export const buildQuestions = buildCollection({
  path: "questions",
  defaultSize: "xs",
  schema: questionsSchema,
  name: "Preguntas frecuentes",
  inlineEditing: false,
  group: "RH",
  exportable: false,
  pagination: true,
  permissions: ({ authController }) => ({
    edit:
      authController.extra.roles.includes("admin") ||
      authController.extra.roles.includes("rrhh"),
    create:
      authController.extra.roles.includes("admin") ||
      authController.extra.roles.includes("rrhh"),
    delete:
      authController.extra.roles.includes("admin") ||
      authController.extra.roles.includes("rrhh"),
  }),
});
