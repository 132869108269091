import {
  buildCollection,
  buildProperty,
  buildSchema,
  PropertiesOrBuilder,
} from "@camberi/firecms";

export type Dates = {
  title: string;
  content: string;
  date: Date;
  status: string;
  published: boolean;
  search: string[];
};

const datesProperties: PropertiesOrBuilder<Dates> = {
  date: {
    title: "Fecha",
    dataType: "timestamp",
    validation: {
      required: true,
    },
  },
  title: {
    title: "Titulo",
    validation: { required: true },
    dataType: "string",
    columnWidth: 250,
  },
  content: {
    title: "Descripción",
    validation: { required: false },
    dataType: "string",
    config: {
      multiline: true,
      markdown: true,
    },
  },
  status: {
    title: "Status",
    validation: { required: true },
    dataType: "string",
    config: {
      enumValues: {
        new: "Nuevo",
        inReview: "En revisión",
        approved: "Aprobado",
      },
    },
  },
  search: {
    dataType: "array",
    disabled: {
      hidden: true,
    },
  },
  published: ({ values }) =>
    buildProperty({
      title: "Published",
      dataType: "boolean",
      columnWidth: 100,
      disabled:
        values.status === "approved"
          ? false
          : {
              clearOnDisabled: true,
              disabledMessage:
                "Status must be approved in order to enable this the published flag",
            },
    }),
};

export const datesSchema = buildSchema<Dates>({
  name: "Fechas importantes",
  defaultValues: {
    status: "new",
  },
  properties: datesProperties,
});

export const buildDates = buildCollection({
  path: "dates-important",
  defaultSize: "xs",
  schema: datesSchema,
  name: "Fechas importantes",
  inlineEditing: false,
  group: "Publicaciones",
  exportable: false,
  pagination: true,
  permissions: ({ authController }) => ({
    edit:
    authController.extra.roles.includes("admin") ||
    authController.extra.roles.includes("rrhh"),
  create:
    authController.extra.roles.includes("admin") ||
    authController.extra.roles.includes("rrhh"),
  delete:
    authController.extra.roles.includes("admin") ||
    authController.extra.roles.includes("rrhh"),
  }),
});
