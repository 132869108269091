import {
  buildCollection,
  buildProperty,
  buildSchema,
  PropertiesOrBuilder,
} from "@camberi/firecms";

export type CompanyUs = {
  mision: string;
  vision: string;
  proposito: string;
  promesa: string;
  valores: string;
  pilares: string;
  img1: string;
  img2: string;
  img3: string;
  img4: string;
};

const companyProperties: PropertiesOrBuilder<CompanyUs> = {
  valores: {
    title: "Valores",
    validation: { required: false },
    dataType: "string",
    config: {
      multiline: true,
      markdown: true,
      previewAsTag: true,
    },
  },
  pilares: {
    title: "Pilares",
    validation: { required: false },
    dataType: "string",
    config: {
      multiline: true,
      markdown: true,
      previewAsTag: true,
    },
  },
  mision: {
    title: "Mision",
    validation: { required: false },
    dataType: "string",
    config: {
      multiline: true,
      markdown: true,
      previewAsTag: true,
    },
  },
  vision: {
    title: "Vision",
    validation: { required: false },
    dataType: "string",
    config: {
      multiline: true,
      markdown: true,
      previewAsTag: true,
    },
  },
  promesa: {
    title: "Promesa",
    validation: { required: false },
    dataType: "string",
    config: {
      multiline: true,
      markdown: true,
      previewAsTag: true,
    },
  },
  proposito: {
    title: "Proposito",
    validation: { required: false },
    dataType: "string",
    config: {
      multiline: true,
      markdown: true,
      previewAsTag: true,
    },
  },
  img1: buildProperty({
    title: "Imagen 1",
    dataType: "string",
    config: {
      storageMeta: {
        mediaType: "image",
        storagePath: "company-info",
        acceptedFiles: ["image/*"],
        storeUrl: true,
      },
    },
  }),
  img2: buildProperty({
    title: "Imagen 2",
    dataType: "string",
    config: {
      storageMeta: {
        mediaType: "image",
        storagePath: "company-info",
        acceptedFiles: ["image/*"],
        storeUrl: true,
      },
    },
  }),
  img3: buildProperty({
    title: "Imagen 3",
    dataType: "string",
    config: {
      storageMeta: {
        mediaType: "image",
        storagePath: "company-info",
        acceptedFiles: ["image/*"],
        storeUrl: true,
      },
    },
  }),
  img4: buildProperty({
    title: "Imagen 4",
    dataType: "string",
    config: {
      storageMeta: {
        mediaType: "image",
        storagePath: "company-info",
        acceptedFiles: ["image/*"],
        storeUrl: true,
      },
    },
  }),
};

export const companySchema = buildSchema<CompanyUs>({
  name: "Informacíon de la compañia",
  properties: companyProperties,
});

export const buildCompany = buildCollection({
  path: "company",
  defaultSize: "xs",
  schema: companySchema,
  name: "Informacíon de la compañia",
  inlineEditing: false,
  properties: [
    "mision",
    "vision",
    "proposito",
    "promesa",
    "valores",
    "pilares",
    "img1",
    "img2",
    "img3",
    "img4",
  ],
  group: "Compañia",
  exportable: false,
  pagination: true,
  permissions: ({ authController }) => ({
    edit:
      authController.extra.roles.includes("admin") ||
      authController.extra.roles.includes("rrhh"),
    create:
      authController.extra.roles.includes("admin") ||
      authController.extra.roles.includes("rrhh"),
    delete:
      authController.extra.roles.includes("admin") ||
      authController.extra.roles.includes("rrhh"),
  }),
});
