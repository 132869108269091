import {
  buildCollection,
  buildEntityCallbacks,
  buildSchema,
  EntityOnSaveProps,
  PropertiesOrBuilder,
} from "@camberi/firecms";

export type Category = {
  name: string;
  description: string;
  creationDate: Date;
  modifiedDate: Date;
};

const categoryProperties: PropertiesOrBuilder<Category> = {
  creationDate: {
    dataType: "timestamp",
    disabled: {
      hidden: true,
    },
    validation: {
      required: true,
    },
  },
  modifiedDate: {
    dataType: "timestamp",
    disabled: {
      hidden: true,
    },
  },
  name: {
    title: "Nombre",
    validation: { required: true },
    dataType: "string",
    columnWidth: 250,
  },
  description: {
    title: "Descripción",
    validation: { required: false },
    dataType: "string",
    config: {
      multiline: true,
      markdown: true,
    },
  },
};

export const categorySchema = buildSchema<Category>({
  name: "Categoría",
  defaultValues: {
    creationDate: new Date(),
  },
  properties: categoryProperties,
});

export const categoryCallbacks = buildEntityCallbacks({
  onPreSave: ({ values }) => {
    values.modifiedDate = new Date();
    return values;
  },
  onSaveSuccess: (props: EntityOnSaveProps<Category>) => {
    //console.log("onSaveSuccess", props);
  },
});

export const categoryPost = buildCollection({
  defaultSize: "xs",
  path: "categories",
  schema: categorySchema,
  name: "Categorías",
  inlineEditing: false,
  properties: [
    "name",
    "description",
    "creationDate",
    "modifiedDate",
  ],
  group: "Publicaciones",
  exportable: false,
  pagination: true,
  permissions: ({ authController }) => ({
    edit:
        authController.extra.roles.includes("admin") ||
      authController.extra.roles.includes("rrhh") ||
      authController.extra.roles.includes("controller") ||
      authController.extra.roles.includes(" branch_management"),
    create:
         authController.extra.roles.includes("admin") ||
      authController.extra.roles.includes("rrhh") ||
      authController.extra.roles.includes("controller") ||
      authController.extra.roles.includes(" branch_management"),
    delete:
       authController.extra.roles.includes("admin") ||
      authController.extra.roles.includes("rrhh") ||
      authController.extra.roles.includes("controller") ||
      authController.extra.roles.includes(" branch_management"),
  }),
  callbacks: categoryCallbacks,
});
