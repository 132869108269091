import {
    buildCollection,
    buildProperty,
    buildSchema,
    PropertiesOrBuilder,
  } from "@camberi/firecms";
  export type diasUStorage = {
    nombre: string;
    fechaInicio:Date;
    fechaFin:Date;
    limite:number;
  };  

  const diasUstorage: PropertiesOrBuilder<diasUStorage> = {
    nombre: {
      validation: { required: true },
      title: "Nombre",
      dataType: "string",
    },
    fechaInicio: ({ values }) =>
        buildProperty({
          title: "Fecha inicio",
          description:
            "Si se define fecha inició, es obligatoria la fecha fin",
          dataType: "timestamp",
          validation:
            values.fechaFin == null ? { required: false } : { required: true },
      }),
    fechaFin: ({ values }) =>
    buildProperty({
      title: "Fecha fin",
      description:
        "Si se define fecha fin, es obligatoria la fecha inició",
      dataType: "timestamp",
      validation:
        values.fechaInicio == null ? { required: false } : { required: true },
  }),
    limite:{
      validation:{ required:true },
      title:"Días disponibles",
      dataType:"number"
    }

  }
  export const diasU = buildSchema<diasUStorage>({
    name: "Días U-Storage",
    defaultValues: {
      creationDate: new Date(),
      last_update: new Date(),
      limite:1
    },
    properties: diasUstorage,
  });
  export const buildDiasUStorage = buildCollection({
    defaultSize: "xs",
    schema: diasU,
    path: "diasUstorage",
    name: "Dias U-Storage",
    inlineEditing: false,
    properties: [
      "nombre",
      "fechaInicio",
      "fechaFin",
      "limite"
    ],
    group: "RH",
    exportable: true,
    pagination: true,
    permissions: ({ authController }) => ({
      edit:
        authController.extra.roles.includes("admin") ||
        authController.extra.roles.includes("rrhh"),
      create:
        authController.extra.roles.includes("admin") ||
        authController.extra.roles.includes("rrhh"),
      delete:
        authController.extra.roles.includes("admin") ||
        authController.extra.roles.includes("rrhh"),
    }),
  });
  