import {
  buildCollection,
  buildSchema,
  PropertiesOrBuilder,
} from "@camberi/firecms";

export type Job = {
  name: string;
  creationDate: Date;
};

const jobProperties: PropertiesOrBuilder<Job> = {
  creationDate: {
    dataType: "timestamp",
    disabled: {
      hidden: true,
    },
    validation: {
      required: true,
    },
  },
  name: {
    title: "Puesto",
    validation: { required: true },
    dataType: "string",
    columnWidth: 250,
  },
};

export const jobSchema = buildSchema<Job>({
  name: "Puestos de trabajo en la empresa",
  defaultValues: {
    creationDate: new Date(),
  },
  properties: jobProperties,
});

export const buildJobs = buildCollection({
  path: "jobs-company",
  defaultSize: "xs",
  schema: jobSchema,
  name: "Puestos de trabajo en la empresa",
  inlineEditing: false,
  group: "RH",
  exportable: false,
  pagination: true,
  permissions: ({ authController }) => ({
    edit:
    authController.extra.roles.includes("admin") ||
    authController.extra.roles.includes("rrhh"),
  create:
    authController.extra.roles.includes("admin") ||
    authController.extra.roles.includes("rrhh"),
  delete:
    authController.extra.roles.includes("admin") ||
    authController.extra.roles.includes("rrhh"),
  }),
});
