import {
  buildCollection,
  buildEntityCallbacks,
  buildProperty,
  buildSchema,
  EntityOnSaveProps,
  PropertiesOrBuilder,
} from "@camberi/firecms";

export type FilesStatic = {
  name: string;
  description: string;
  file: string[];
  modifiedDate: Date;
  creationDate: Date;
};

const filesProperties: PropertiesOrBuilder<FilesStatic> = {
  modifiedDate: {
    dataType: "timestamp",
    disabled: {
      hidden: true,
    },
  },
  creationDate: {
    dataType: "timestamp",
    disabled: {
      hidden: true,
    },
  },
  name: {
    title: "Nombre",
    validation: { required: true },
    dataType: "string",
    columnWidth: 250,
  },
  description: {
    title: "Descripción",
    validation: { required: true },
    dataType: "string",
    columnWidth: 250,
  },
  file: buildProperty({
    title: "Archivos",
    dataType: "array",
    of: {
      dataType: "string",
      config: {
        storageMeta: {
          mediaType: "image",
          acceptedFiles: ["image/*", "application/*"],
          storagePath: "files-static",
          storeUrl: true,
        },
      },
    },
  }),
};

export const filesSchema = buildSchema<FilesStatic>({
  name: "Archivos de contenido estatico",
  defaultValues: {
    creationDate: new Date(),
  },
  properties: filesProperties,
});

export const filesCallbacks = buildEntityCallbacks({
  onPreSave: ({ values }) => {
    values.modifiedDate = new Date();
    return values;
  },
  onSaveSuccess: (props: EntityOnSaveProps<FilesStatic>) => {
    //console.log("onSaveSuccess", props);
  },
});

export const buildFiles = buildCollection({
  path: "files-static-content",
  defaultSize: "xs",
  schema: filesSchema,
  name: "Archivos de contenido estatico",
  inlineEditing: false,
  group: "Compañia",
  exportable: false,
  pagination: true,
  permissions: ({ authController }) => ({
    edit:
    authController.extra.roles.includes("admin") ||
    authController.extra.roles.includes("rrhh"),
  create:
    authController.extra.roles.includes("admin") ||
    authController.extra.roles.includes("rrhh"),
  delete:
    authController.extra.roles.includes("admin") ||
    authController.extra.roles.includes("rrhh"),
  }),
  callbacks: filesCallbacks,
});
