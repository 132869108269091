import {
  buildCollection,
  buildProperty,
  buildSchema,
  PropertiesOrBuilder,
} from "@camberi/firecms";

export type recognitions = {
  name: string;
  icon: string;
};

const recognitionsProperties: PropertiesOrBuilder<recognitions> = {
  name: {
    title: "Nombre",
    validation: { required: true },
    dataType: "string",
    columnWidth: 250,
  },
  icon: buildProperty({
    title: "Imagen",
    dataType: "string",
    description: "La imagen deber ser 515x512",
    config: {
      storageMeta: {
        mediaType: "image",
        storagePath: "recognitions",
        acceptedFiles: ["image/*"],
        storeUrl: true,
      },
    },
  }),
};

export const recognitionsSchema = buildSchema<recognitions>({
  name: "Intereses",
  properties: recognitionsProperties,
});

export const buildrecognitions = buildCollection({
  path: "recognitions",
  defaultSize: "xs",
  schema: recognitionsSchema,
  name: "Reconocimientos",
  inlineEditing: false,
  properties: ["name", "icon"],
  group: "RH",
  exportable: false,
  pagination: true,
  permissions: ({ authController }) => ({
    edit:
      authController.extra.roles.includes("admin") ||
      authController.extra.roles.includes("rrhh"),
    create:
      authController.extra.roles.includes("admin") ||
      authController.extra.roles.includes("rrhh"),
    delete:
      authController.extra.roles.includes("admin") ||
      authController.extra.roles.includes("rrhh"),
  }),
});
