import {
  buildCollection,
  buildProperty,
  buildSchema,
  EntityReference,
  PropertiesOrBuilder,
} from "@camberi/firecms";

export type User = {
  dob: Date;
  email: string;
  emailMask: string;
  gender: string;
  lastname: string;
  name: string;
  role: string;
  photoUser: string;
  about?: string;
  area?: EntityReference;
  cellphone?: string;
  branch?: EntityReference;
  userAproveedVacations: EntityReference;
  officeTel?: string;
  photo?: string;
  isLegalAdmin?: boolean;
  puesto: EntityReference;
  ext?: string;
  idEmployed: number;
  interests?: number[];
  recognitions?: EntityReference[];
  direccion: EntityReference;
  dateIn: Date;
  vacationDay: number;
  infoUserRH: {
    nameEmergency: string;
    telEmergency: string;
    bloodType: string;
    allergy: string;
    affections: string;
  };
  leyVacation: EntityReference;
  businessDays: {
    1: boolean;
    2: boolean;
    3: boolean;
    4: boolean;
    5: boolean;
    6: boolean;
    0: boolean;
  };
};

const userProperties: PropertiesOrBuilder<User> = {
  idEmployed: {
    validation: { required: true },
    title: "Id de empleado",
    dataType: "number",
  },
  name: {
    validation: { required: true },
    title: "Nombre",
    dataType: "string",
  },
  lastname: {
    validation: { required: true },
    title: "Apellidos",
    dataType: "string",
  },
  gender: {
    title: "Género",
    validation: { required: true },
    dataType: "string",
    config: {
      enumValues: {
        male: "Hombre",
        female: "Mujer",
      },
    },
  },
  dob: {
    title: "Fecha de nacimiento",
    dataType: "timestamp",
  },
  email: {
    validation: {
      required: true,
      email: true,
      matches: new RegExp(
        "@intranetfs.com.mx$|@guardabox.com$|@u-storage.com.mx$|@emt.dev$"
      ),
    },
    title: "Email",
    dataType: "string",
  },

  role: {
    validation: { required: true },
    title: "Rol",
    dataType: "string",
    config: {
      enumValues: {
        branch_employee: "Empleado sucursal",
        branch_employee_limit: "Empleado sucursal_limitado",
        corporate_employee: "Empleado corporativo",
        corporate_branch_dms_gender: "CORPORATIVO/SUCURSAL – DMS Y GERENTE OPS",
        branch_management: "Gerente Director",
        rrhh: "RRHH",
        admin: "Admin (TI)",
        controller: "Contralor",
      },
    },
  },
  dateIn: {
    validation: { required: true },
    title: "Fecha de ingreso",
    dataType: "timestamp",
  },
  vacationDay: {
    validation: { required: true },
    title: "Dias disponibles de vaciones",
    dataType: "number",
  },
  about: {
    title: "Sobre Mi",
    dataType: "string",
    disabled: {
      hidden: true,
    },
  },
  branch: buildProperty({
    title: "Sucursal",
    description:
      "Para los empleados de corporativo, dejar esta información en blanco",
    dataType: "reference",
    path: "branch_office",
    previewProperties: ["name"],
  }),
  userAproveedVacations: ({ values }) =>
    buildProperty({
      title: "VoBo Vacaciones",
      description:
        "Si el usuario no es parte de una sucursal declara quien debe aprobar sus vacaciones",
      dataType: "reference",
      path: "users",
      previewProperties: ["name", "lastname", "puesto"],
      validation:
        values.branch != null ? { required: false } : { required: true },
    }),
  area: buildProperty({
    validation: { required: true },
    title: "Area",
    dataType: "reference",
    path: "areas-company",
    previewProperties: ["name"],
  }),
  // TODO
  isLegalAdmin: {
    validation: { required: false },
    title: "Es administrador de legal",
    dataType: "boolean",
  },
  puesto: buildProperty({
    validation: { required: true },
    title: "Puesto",
    dataType: "reference",
    path: "jobs-company",
    previewProperties: ["name"],
  }),

  emailMask: ({ values }) =>
    buildProperty({
      title: "Correo administrativo",
      description: `Si este usuario es Asistente Operativo Sucursal, Gerente de Sucursal o Subgerente de sucursal, debes agregar un email que sera visible en la intranet`,
      dataType: "string",
      columnWidth: 100,
      validation:
        values.puesto?.id === "5WZovOIhMmfY1SdC4GAo" ||
        values.puesto?.id === "A2Vc6cUZHKq84XmM4kRL" ||
        values.puesto?.id === "zMXkZgzOFN1zbafmzLvM"
          ? { required: true }
          : { required: false },
    }),
  direccion: {
    title: "Direccion",
    validation: { required: true },
    dataType: "reference",
    path: "directions-company",
    previewProperties: ["name"],
  },
  cellphone: {
    title: "Teléfono Celular",
    dataType: "string",
  },
  officeTel: {
    title: "Teléfono de oficina",
    dataType: "string",
  },
  ext: {
    title: "Extención",
    dataType: "string",
  },
  photoUser: ({ values }) =>
    buildProperty({
      title: "Foto de perfil",
      dataType: "string",
      config: {
        storageMeta: {
          mediaType: "image",
          storagePath: "photoUser/" + values.idEmployed + values.lastname,
          acceptedFiles: ["image/*"],
          storeUrl: true,
        },
      },
    }),
  photo: ({ values }) =>
    buildProperty({
      title: "Foto de portada",
      dataType: "string",
      config: {
        storageMeta: {
          mediaType: "image",
          storagePath: "photoUser/" + values.idEmployed + values.lastname,
          acceptedFiles: ["image/*"],
          storeUrl: true,
        },
      },
    }),
  recognitions: {
    title: "Reconocimientos",
    dataType: "array",
    of: {
      dataType: "reference",
      description: "Selecciona el reconocimiento",
      path: "recognitions",
      previewProperties: ["name"],
    },
  },
  infoUserRH: buildProperty({
    title: "Informacion de contacto de emergencia",
    validation: { required: true },
    dataType: "map",
    properties: {
      nameEmergency: {
        title: "Contacto de emergencia",
        dataType: "string",
      },
      telEmergency: {
        title: "Telefono de emergencia",
        dataType: "string",
      },
      bloodType: {
        title: "Tipo de sangre",
        dataType: "string",
        config: {
          enumValues: {
            "O negativo": "O negativo",
            "O positivo": "O positivo",
            "A negativo": "A negativo",
            "A positivo": "A positivo",
            "B negativo": "B negativo",
            "B positivo": "B positivo",
            "AB negativo": "AB negativo",
            "AB positivo": "AB positivo",
          },
        },
      },
      allergy: {
        title: "Alergias",
        dataType: "string",
      },
      affections: {
        title: "Afecciones",
        dataType: "string",
      },
    },
  }),
  leyVacation: {
    validation: { required: true },
    title: "Ley de vacaciones",
    dataType: "reference",
    path: "vacation",
    previewProperties: ["nombre"],
  },
  businessDays: buildProperty({
    title: "Dias laborales",
    validation: { required: true },
    dataType: "map",
    properties: {
      0: {
        title: "Domingo",
        dataType: "boolean",
      },
      1: {
        title: "Lunes",
        dataType: "boolean",
      },
      2: {
        title: "Martes",
        dataType: "boolean",
      },
      3: {
        title: "Miércoles",
        dataType: "boolean",
      },
      4: {
        title: "Jueves",
        dataType: "boolean",
      },
      5: {
        title: "Viernes",
        dataType: "boolean",
      },

      6: {
        title: "Sabado",
        dataType: "boolean",
      },
    },
  }),
};

export const userSchema = buildSchema<User>({
  name: "Usuarios",
  defaultValues: {
    creationDate: new Date(),
    last_update: new Date(),
    isLegalAdmin: false,
    vacationDay: 6,
    businessDays: {
      0: false,
      1: true,
      2: true,
      3: true,
      4: true,
      5: true,
      6: false,
    },
  },

  properties: userProperties,
});

export const buildUser = buildCollection({
  defaultSize: "xs",
  schema: userSchema,
  path: "users",
  name: "Usuarios",

  inlineEditing: false,
  properties: [
    "idEmployed",
    "name",
    "lastname",
    "email",
    "emailMask",
    "role",
    "area",
    "brach",
    "isLegalAdmin",
    "puesto",
    "dateIn",
    "vacationDay",
    "userAproveedVacations",
  ],
  group: "RH",
  exportable: true,
  pagination: true,
  permissions: ({ authController }) => ({
    edit:
      authController.extra.roles.includes("admin") ||
      authController.extra.roles.includes("rrhh"),
    create:
      authController.extra.roles.includes("admin") ||
      authController.extra.roles.includes("rrhh"),
    delete:
      authController.extra.roles.includes("admin") ||
      authController.extra.roles.includes("rrhh"),
  }),
});
