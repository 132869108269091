import {
  buildCollection,
  buildEntityCallbacks,
  buildProperty,
  buildSchema,
  EntityOnSaveProps,
  EntityReference,
  PropertiesOrBuilder,
} from "@camberi/firecms";

export type BrancOffice = {
  about: string;
  mail: string;
  phone: string;
  manager: EntityReference;
  brand: EntityReference;
  direction: string;
  admin_burden_rating: number;
  affluence_rating: number;
  average_size: number;
  billing_rating: number;
  infrastructure: string;
  locationiFrame: string;
  location: string;
  railyard: string;
  size_rating: number;
  visibility_rating: number;
  comercial_need_rating: number;
  competence: string;
  control_access: string;
  fiber: boolean;
  floors: string;
  format: string;
  infrastructure_rating: number;
  inputs_outputs_rating: number;
  last_update: Date;
  location_rating: number;
  name: string;
  nearby_branches: EntityReference[];
  occupation: number;
  occupation_warehouses: number;
  operation_start: Date;
  other_features: string;
  potential_market_rating: number;
  profitable_meters: number;
  railyard_rating: number;
  solar_panels: boolean;
  specific_challenges: string[];
  status: string;
  street_rate: number;
  total_potential: number;
  comertial_client_square_meters: number;
  residential_client_square_meters: number;
  comertial_client_unit: number;
  residential_client_unit: number;
  locker: number;
  std_0_0_4_5: number;
  std_4_5_14_9: number;
  std_14_9_27_0: number;
  std_27: number;
  warehouses: number;
  zones_service: string[];
  distrito: EntityReference;
  businessName: string;
};

const branchProperties: PropertiesOrBuilder<BrancOffice> = {
  brand: buildProperty({
    validation: { required: true },
    title: "Marca de la sucursal",
    dataType: "reference",
    path: "brands",
    previewProperties: ["name"],
  }),
  name: {
    title: "Nombre de la sucursal",
    validation: { required: true },
    dataType: "string",
    columnWidth: 250,
  },
  mail: {
    title: "Correo de la sucursal",
    validation: { required: true },
    dataType: "string",
    columnWidth: 250,
  },
  phone: {
    title: "Telefono de la sucursal",
    validation: { required: true },
    dataType: "string",
    columnWidth: 250,
  },
  manager: buildProperty({
    title: "Gerente de la sucursal",
    dataType: "reference",
    path: "users",
    previewProperties: ["name", "lastname"],
  }),
  direction: {
    title: "Direccion de la sucursal",
    validation: { required: true },
    dataType: "string",
    columnWidth: 250,
  },
  location: {
    title: "Ubicación (URL)",
    dataType: "string",
    columnWidth: 250,
  },
  locationiFrame: {
    title: "Ubicación iframe (URL)",
    dataType: "string",
  },
  distrito: buildProperty({
    title: "Distrito",
    dataType: "reference",
    path: "distritos",
    previewProperties: ["name"],
  }),
  businessName: {
    title: "Razón Social",
    validation: { required: true },
    dataType: "string",
  },
  operation_start: { title: "Apertura", dataType: "timestamp" },
  status: {
    title: "Status",
    dataType: "string",
    config: {
      enumValues: {
        filling: "En llenado",
        stabilized: "Estabilizado",
      },
    },
    columnWidth: 250,
  },
  competence: {
    title: "Competencia",
    validation: { required: true },
    dataType: "string",
    columnWidth: 250,
  },
  nearby_branches: {
    title: "Sucursales US cercanas",
    dataType: "array",
    of: {
      dataType: "reference",
      description: "Selecciona las sucursales cercanas",
      path: "branch_office",
      previewProperties: ["name"],
    },
  },
  zones_service: {
    title: "Servicio a Colonias/Zonas",
    validation: { required: true },
    dataType: "array",
    of: {
      dataType: "string",
    },
  },
  format: {
    title: "Formato",
    validation: { required: true },
    dataType: "string",
    columnWidth: 250,
  },
  floors: {
    title: "Pisos",
    validation: { required: true },
    dataType: "string",
    columnWidth: 250,
  },
  fiber: {
    title: "Fibra",
    dataType: "boolean",
  },
  solar_panels: {
    title: "Paneles solares",
    dataType: "boolean",
    columnWidth: 250,
  },
  total_potential: {
    title: "Potencial total m2",
    dataType: "number",
    columnWidth: 250,
  },
  profitable_meters: {
    title: "Metros rentables",
    validation: { required: true },
    dataType: "number",
    columnWidth: 250,
  },
  warehouses: {
    title: "Bodegas (Cantidad)",
    validation: { required: true },
    dataType: "number",
    columnWidth: 250,
  },
  average_size: {
    title: "Tamaño promedio (m2)",
    validation: { required: true },
    dataType: "number",
    columnWidth: 250,
  },
  street_rate: {
    title: "Street Rate",
    validation: { required: true },
    dataType: "number",
    columnWidth: 250,
  },
  occupation: {
    title: "Ocupación (m2) %",
    validation: { required: true },
    dataType: "number",
    columnWidth: 250,
  },
  occupation_warehouses: {
    title: "Ocupación (Bodegas) %",
    validation: { required: true },
    dataType: "number",
    columnWidth: 250,
  },
  location_rating: {
    title: "Ubicación",
    description: "Califica del 1 (malo) al 5 (Excelente) ",
    validation: { required: true },
    dataType: "number",
    config: {
      enumValues: {
        1: "1",
        2: "2",
        3: "3",
        4: "4",
        5: "5",
      },
    },
    columnWidth: 250,
  },
  visibility_rating: {
    title: "Visibilidad de la sucursal",
    description: "Califica del 1 (malo) al 5 (Excelente) ",
    validation: { required: true },
    dataType: "number",
    config: {
      enumValues: {
        1: "1",
        2: "2",
        3: "3",
        4: "4",
        5: "5",
      },
    },
    columnWidth: 250,
  },
  railyard_rating: {
    title: "Patio de maniobras",
    description: "Califica del 1 (Malo) al 5 (Excelente) ",
    validation: { required: true },
    dataType: "number",
    config: {
      enumValues: {
        1: "1",
        2: "2",
        3: "3",
        4: "4",
        5: "5",
      },
    },
    columnWidth: 250,
  },
  infrastructure_rating: {
    title: "Infraestructura",
    description: "Califica del 1 (Malo) al 5 (Excelente) ",
    validation: { required: true },
    dataType: "number",
    config: {
      enumValues: {
        1: "1",
        2: "2",
        3: "3",
        4: "4",
        5: "5",
      },
    },
    columnWidth: 250,
  },
  billing_rating: {
    title: "Facturación",
    description: "Califica del 1 (Chica) al 5 (Muy grande) ",
    validation: { required: true },
    dataType: "number",
  },
  size_rating: {
    title: "Tamaño de sucursal",
    description: "Califica del 1 (Chica) al 5 (Muy grande) ",
    validation: { required: true },
    config: {
      enumValues: {
        1: "1",
        2: "2",
        3: "3",
        4: "4",
        5: "5",
      },
    },
    dataType: "number",
    columnWidth: 250,
  },
  potential_market_rating: {
    title: "Mercado potencial (OPI)",
    description: "Califica del 1 (Chica) al 5 (Muy grande) ",
    validation: { required: true },
    dataType: "number",
    config: {
      enumValues: {
        1: "1",
        2: "2",
        3: "3",
        4: "4",
        5: "5",
      },
    },
    columnWidth: 250,
  },
  affluence_rating: {
    title: "Afluencia",
    description: "Califica del 1 (Poca) al 5 (Mucha) ",
    validation: { required: true },
    dataType: "number",
    config: {
      enumValues: {
        1: "1",
        2: "2",
        3: "3",
        4: "4",
        5: "5",
      },
    },
    columnWidth: 250,
  },
  inputs_outputs_rating: {
    title: "Entradas/salidas (Cant)",
    description: "Califica del 1 (Poca) al 5 (Mucha) ",
    validation: { required: true },
    dataType: "number",
    config: {
      enumValues: {
        1: "1",
        2: "2",
        3: "3",
        4: "4",
        5: "5",
      },
    },
    columnWidth: 250,
  },
  admin_burden_rating: {
    title: "Carga administrativa",
    description: "Califica del 1 (Baja) al 5 (Muy alta) ",
    validation: { required: true },
    dataType: "number",
    config: {
      enumValues: {
        1: "1",
        2: "2",
        3: "3",
        4: "4",
        5: "5",
      },
    },
    columnWidth: 250,
  },
  comercial_need_rating: {
    title: "Necesidades comerciales",
    description: "Califica del 1 (Baja) al 5 (Muy alta) ",
    validation: { required: true },
    dataType: "number",
    config: {
      enumValues: {
        1: "1",
        2: "2",
        3: "3",
        4: "4",
        5: "5",
      },
    },
    columnWidth: 250,
  },

  about: {
    title: "Sobre la sucursal",
    validation: { required: true },
    dataType: "string",
    columnWidth: 250,
  },

  infrastructure: {
    title: "Infraestructura",
    validation: { required: true },
    dataType: "string",
    columnWidth: 250,
  },
  railyard: {
    title: "Patio de maniobras",
    validation: { required: true },
    dataType: "string",
    columnWidth: 250,
  },
  other_features: {
    title: "Otras características",
    validation: { required: true },
    dataType: "string",
    columnWidth: 250,
  },
  specific_challenges: {
    title: "Retos especificos",
    validation: { required: true },
    dataType: "array",
    of: {
      dataType: "string",
    },
  },
  control_access: {
    title: "Control y acceso",
    validation: { required: true },
    dataType: "string",
  },
  last_update: {
    title: "última actualización",
    disabled: {
      hidden: true,
    },
    dataType: "timestamp",
    columnWidth: 250,
  },
  comertial_client_square_meters: {
    title: "Tipo de cliente comercial (m^2)",
    validation: { required: true },
    dataType: "number",
    columnWidth: 250,
  },
  residential_client_square_meters: {
    title: "Tipo de cliente residencial (m^2)",
    validation: { required: true },
    dataType: "number",
    columnWidth: 250,
  },
  comertial_client_unit: {
    title: "Tipo de cliente comercial (Unidades)",
    validation: { required: true },
    dataType: "number",
    columnWidth: 250,
  },
  residential_client_unit: {
    title: "Tipo de cliente residencial (Unidades)",
    validation: { required: true },
    dataType: "number",
    columnWidth: 250,
  },
  locker: {
    title: "Unit Mix (m^2) Locker",
    validation: { required: true },
    dataType: "number",
    columnWidth: 250,
  },
  std_0_0_4_5: {
    title: "Unit Mix (m^2) std de 0.0 a 4.5",
    validation: { required: true },
    dataType: "number",
    columnWidth: 250,
  },
  std_4_5_14_9: {
    title: "Unit Mix (m^2) std de 4.5 a 14.9",
    validation: { required: true },
    dataType: "number",
    columnWidth: 250,
  },
  std_14_9_27_0: {
    title: "Unit Mix (m^2) std de 14.9 a 27.0",
    validation: { required: true },
    dataType: "number",
    columnWidth: 250,
  },
  std_27: {
    title: "Unit Mix (m^2) std de 21.0 o más",
    validation: { required: true },
    dataType: "number",
    columnWidth: 250,
  },
};

export const branchSchema = buildSchema<BrancOffice>({
  name: "Sucursales",
  defaultValues: {
    creationDate: new Date(),
    last_update: new Date(),
  },
  properties: branchProperties,
});

export const branchsCallbacks = buildEntityCallbacks({
  onPreSave: ({ values }) => {
    values.last_update = new Date();
    return values;
  },
  onSaveSuccess: (props: EntityOnSaveProps<BrancOffice>) => {
    //console.log("onSaveSuccess", props);
  },
});

export const buildbranch = buildCollection({
  path: "branch_office",
  schema: branchSchema,
  name: "Sucursales",
  defaultSize: "xs",
  inlineEditing: false,
  properties: [
    "name",
    "mail",
    "phone",
    "manager",
    "brand",
    "direction",
    "status",
    "distrito",
    "businessName",
  ],
  group: "Sucursales",
  exportable: false,
  pagination: true,
  permissions: ({ authController }) => ({
    edit:
      authController.extra.roles.includes("admin") ||
      authController.extra.roles.includes("rrhh") ||
      authController.extra.roles.includes("controller"),
    create:
    authController.extra.roles.includes("admin") ||
    authController.extra.roles.includes("rrhh") ||
    authController.extra.roles.includes("controller"),
    delete:
    authController.extra.roles.includes("admin") ||
    authController.extra.roles.includes("rrhh") ||
    authController.extra.roles.includes("controller"),
  }),
  callbacks: branchsCallbacks,
});
