import {
    buildCollection,
    buildSchema,
    PropertiesOrBuilder,
  } from "@camberi/firecms";
  
  export type CatalogSocieties = {
    societyType: string;
  };
  
  const CatalogSocietiesPrperties: PropertiesOrBuilder<CatalogSocieties> = {
    societyType: {
      validation: { required: true },
      title: "Sociedades / Asociados",
      dataType: "string"
    }   
  };
  
  export const societySchema = buildSchema<CatalogSocieties>({
    name: "Catalógo de Sociedades / Asociaciones",
    defaultValues: {
      societyType: "",
    },
    properties: CatalogSocietiesPrperties,
  });
  
  export const buildCatalogSocietiesCollection = buildCollection({
    defaultSize: "xs",
    schema: societySchema,
    path: "societies",
    name: "Catalógo de Sociedades / Asociaciones",
    inlineEditing: false,
    properties: ["societyType"],
    group: "Legal",
    exportable: true,
    pagination: true,
    permissions: ({ authController }) => ({
        edit:
          authController.extra.roles.includes("admin") ||
          authController.extra.roles.includes("rrhh"),
        create:
          authController.extra.roles.includes("admin") ||
          authController.extra.roles.includes("rrhh"),
        delete:
          authController.extra.roles.includes("admin") ||
          authController.extra.roles.includes("rrhh"),
      }),
  });
  