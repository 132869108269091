import {
  buildCollection,
  buildSchema,
  EntityReference,
  PropertiesOrBuilder,
} from "@camberi/firecms";

export type Areas = {
  name: string;
  creationDate: Date;
  jobs?: EntityReference[];
};

const areasProperties: PropertiesOrBuilder<Areas> = {
  creationDate: {
    dataType: "timestamp",
    disabled: {
      hidden: true,
    },
    validation: {
      required: true,
    },
  },
  name: {
    title: "Area",
    validation: { required: true },
    dataType: "string",
    columnWidth: 250,
  },
  jobs: {
    title: "Puestos de trabajo",
    dataType: "array",
    of: {
      dataType: "reference",
      description: "Selecciona los puestos",
      path: "jobs-company",
      previewProperties: ["name"],
    },
  },
};

export const areasSchema = buildSchema<Areas>({
  name: "Areas de la empresa",
  defaultValues: {
    creationDate: new Date(),
  },
  properties: areasProperties,
});

export const buildAreas = buildCollection({
  path: "areas-company",
  defaultSize: "xs",
  schema: areasSchema,
  name: "Areas de la empresa",
  inlineEditing: false,
  group: "RH",
  exportable: false,
  pagination: true,
  permissions: ({ authController }) => ({
    edit:
      authController.extra.roles.includes("admin") ||
      authController.extra.roles.includes("rrhh"),
    create:
      authController.extra.roles.includes("admin") ||
      authController.extra.roles.includes("rrhh"),
    delete:
      authController.extra.roles.includes("admin") ||
      authController.extra.roles.includes("rrhh"),
  }),
});
