import {
  buildCollection,
  buildEntityCallbacks,
  buildProperty,
  buildSchema,
  EntityOnSaveProps,
  PropertiesOrBuilder,
} from "@camberi/firecms";

export type AdvertisementsPost = {
  name: string;
  content: string;
  creationDate: Date;
  modifiedDate: Date;
  status: string;
  published: boolean;
};

const advertisementProperties: PropertiesOrBuilder<AdvertisementsPost> = {
  creationDate: {
    dataType: "timestamp",
    disabled: {
      hidden: true,
    },
    validation: {
      required: true,
    },
  },
  modifiedDate: {
    dataType: "timestamp",
    disabled: {
      hidden: true,
    },
  },
  name: {
    title: "Titulo",
    validation: { required: true },
    dataType: "string",
    columnWidth: 250,
  },
  content: {
    title: "Descripción",
    validation: { required: false },
    dataType: "string",
    config: {
      multiline: true,
      markdown: true,
    },
  },
  status: {
    title: "Status",
    validation: { required: true },
    dataType: "string",
    config: {
      enumValues: {
        new: "Nuevo",
        inReview: "En revisión",
        approved: "Aprobado",
      },
    },
  },
  published: ({ values }) =>
    buildProperty({
      title: "Published",
      dataType: "boolean",
      columnWidth: 100,
      disabled:
        values.status === "approved"
          ? false
          : {
              clearOnDisabled: true,
              disabledMessage:
                "Status must be approved in order to enable this the published flag",
            },
    }),
};

export const advertisementSchema = buildSchema<AdvertisementsPost>({
  name: "Anuncios",
  defaultValues: {
    creationDate: new Date(),
    status: "new",
  },
  properties: advertisementProperties,
});

export const advertisementsCallbacks = buildEntityCallbacks({
  onPreSave: ({ values }) => {
    values.modifiedDate = new Date();
    return values;
  },
  onSaveSuccess: (props: EntityOnSaveProps<AdvertisementsPost>) => {
    //console.log("onSaveSuccess", props);
  },
});

export const buildAdvertisement = buildCollection({
  defaultSize: "xs",
  path: "advertisements",
  schema: advertisementSchema,
  name: "Anuncios",
  inlineEditing: false,
  properties: [
    "name",
    "content",
    "creationDate",
    "modifiedDate",
    "status",
    "published",
  ],
  group: "Publicaciones",
  exportable: false,
  pagination: true,
  permissions: ({ authController }) => ({
    edit:
      authController.extra.roles.includes("admin") ||
      authController.extra.roles.includes("rrhh") ||
      authController.extra.roles.includes("controller") ||
      authController.extra.roles.includes(" branch_management"),

    create:
      authController.extra.roles.includes("admin") ||
      authController.extra.roles.includes("rrhh") ||
      authController.extra.roles.includes("controller") ||
      authController.extra.roles.includes(" branch_management"),
    delete:
      authController.extra.roles.includes("admin") ||
      authController.extra.roles.includes("rrhh") ||
      authController.extra.roles.includes("controller") ||
      authController.extra.roles.includes(" branch_management"),
  }),
  callbacks: advertisementsCallbacks,
});
