import {
  buildCollection,
  buildProperty,
  buildSchema,
  PropertiesOrBuilder,
} from "@camberi/firecms";

export type Brands = {
  name: string;
  creationDate: Date;
  imagen: string;
  imagenSquare: string;
};

const brandsProperties: PropertiesOrBuilder<Brands> = {
  creationDate: {
    dataType: "timestamp",
    disabled: {
      hidden: true,
    },
    validation: {
      required: true,
    },
  },
  name: {
    title: "Nombre de la marca",
    validation: { required: true },
    dataType: "string",
    columnWidth: 250,
  },
  imagen: ({ values }) =>
    buildProperty({
      title: "Logo de la marca",
      description: "La imagen deber ser 440x90",
      dataType: "string",
      config: {
        storageMeta: {
          mediaType: "image",
          storagePath: "brands-images/" + values.name,
          acceptedFiles: ["image/*"],
          storeUrl: true,
        },
      },
    }),
  imagenSquare: ({ values }) =>
    buildProperty({
      title: "Logo de la marca (2)",
      description: "La imagen deber ser 355x355",
      dataType: "string",
      config: {
        storageMeta: {
          mediaType: "image",
          storagePath: "brands-images/" + +values.name,
          acceptedFiles: ["image/*"],
          storeUrl: true,
        },
      },
    }),
};

export const brandsSchema = buildSchema<Brands>({
  name: "Marcas de la empresa",
  defaultValues: {
    creationDate: new Date(),
  },
  properties: brandsProperties,
});

export const buildBrands = buildCollection({
  path: "brands",
  defaultSize: "xs",
  schema: brandsSchema,
  name: "Marcas de la empresa",
  inlineEditing: false,
  group: "Sucursales",
  exportable: false,
  pagination: true,
  permissions: ({ authController }) => ({
    edit:
      authController.extra.roles.includes("admin") ||
      authController.extra.roles.includes("rrhh") ||
      authController.extra.roles.includes("controller"),
    create:
      authController.extra.roles.includes("admin") ||
      authController.extra.roles.includes("rrhh") ||
      authController.extra.roles.includes("controller"),
    delete:
      authController.extra.roles.includes("admin") ||
      authController.extra.roles.includes("rrhh") ||
      authController.extra.roles.includes("controller"),
  }),
});
