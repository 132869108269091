import {
  buildCollection,
  buildProperty,
  buildSchema,
  PropertiesOrBuilder,
} from "@camberi/firecms";

export type Plan = {
  name: string;
  vision: string;
  mision: string;
  strategicObjectives: string[];
  creationDate: Date;
  iniciativas: any[];
};

const planProperties: PropertiesOrBuilder<Plan> = {
  creationDate: {
    dataType: "timestamp",
    disabled: {
      hidden: true,
    },
    validation: {
      required: true,
    },
  },
  name: {
    title: "Nombre",
    validation: { required: true },
    dataType: "string",
    columnWidth: 250,
  },
  vision: {
    title: "Visión",
    validation: { required: false },
    dataType: "string",
    config: {
      multiline: true,
      markdown: true,
    },
  },
  mision: {
    title: "Misión",
    validation: { required: false },
    dataType: "string",
    config: {
      multiline: true,
      markdown: true,
    },
  },
  strategicObjectives: {
    title: "Objetivos estrategicos",
    validation: { required: true },
    dataType: "array",
    of: {
      dataType: "string",
      config: {
        multiline: true,
        markdown: true,
      },
    },
  },
  iniciativas: buildProperty({
    title: "Iniciativas",
    validation: { required: true },
    dataType: "array",
    of: {
      dataType: "map",
      properties: {
        nombre: {
          title: "Nombre Iniciativa",
          dataType: "string",
        },
        tareas: buildProperty({
          title: "Tareas",
          validation: { required: true },
          dataType: "array",
          of: {
            dataType: "map",
            properties: {
              nombre: {
                title: "Nombre tarea",
                dataType: "string",
              },
              description: {
                title: "Descripción tarea",
                dataType: "string",
              },
              lider: {
                title: "Lider",
                dataType: "string",
              },
              colider: {
                title: "Colider",
                dataType: "string",
              },
              docs: buildProperty({
                title: "Documentos de la tarea",
                dataType: "array",
                of: {
                  dataType: "map",
                  properties: {
                    nombre: {
                      title: "Nombre",
                      dataType: "string",
                    },
                    autor: {
                      title: "Autor",
                      dataType: "string",
                    },
                    creationDate: {
                      dataType: "timestamp",
                      disabled: {
                        hidden: true,
                      },
                    },
                    document: buildProperty({
                      title: "Doumento",
                      dataType: "string",
                      config: {
                        storageMeta: {
                          storagePath: "planes-documents",
                          acceptedFiles: ["application/*"],
                          storeUrl: true,
                        },
                      },
                    }),
                  },
                },
              }),
            },
          },
        }),
      },
    },
  }),
};

export const planSchema = buildSchema<Plan>({
  name: "Publicaciones Noticias",
  defaultValues: {
    creationDate: new Date(),
    status: "new",
  },
  properties: planProperties,
});

export const buildPlan = buildCollection({
  path: "planes",
  defaultSize: "xs",
  schema: planSchema,
  name: "Planes estrategicos",
  inlineEditing: false,
  group: "Compañia",
  exportable: false,
  pagination: true,
  permissions: ({ authController }) => ({
    edit:
      authController.extra.roles.includes("admin") ||
      authController.extra.roles.includes("rrhh"),
    create:
      authController.extra.roles.includes("admin") ||
      authController.extra.roles.includes("rrhh"),
    delete:
      authController.extra.roles.includes("admin") ||
      authController.extra.roles.includes("rrhh"),
  }),
});
