import {
  buildCollection,
  buildSchema,

  PropertiesOrBuilder,
} from "@camberi/firecms";

export type SalesForce = {
  cat1: string;
  cat2: string[];
  
};

const salesforceProperties: PropertiesOrBuilder<SalesForce> = {
  cat1: {
    title: "Categoria principal",
    validation: { required: true },
    dataType: "string",
    columnWidth: 250,
  },
  cat2: {
    title: "Subcategorias",
    dataType: "array",
    of: {
      dataType: "string",
    },
  },
};

export const salesforceSchema = buildSchema<SalesForce>({
  name: "Categorias Salesforce",
  defaultValues: {
    creationDate: new Date(),
  },
  properties: salesforceProperties,
});

export const buildsalesforce = buildCollection({
  path: "salesforceCat",
  defaultSize: "xs",
  schema: salesforceSchema,
  name: "Categorias Salesforce",
  inlineEditing: false,
  group: "Información",
  exportable: false,
  pagination: true,
  permissions: ({ authController }) => ({
    edit:
      authController.extra.roles.includes("admin") ||
      authController.extra.roles.includes("rrhh"),
    create:
      authController.extra.roles.includes("admin") ||
      authController.extra.roles.includes("rrhh"),
    delete:
      authController.extra.roles.includes("admin") ||
      authController.extra.roles.includes("rrhh"),
  }),
});
