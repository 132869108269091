import {
  buildCollection,
  buildProperty,
  buildSchema,
  EntityReference,
  PropertiesOrBuilder,
} from "@camberi/firecms";

export type Policies = {
  name: string;
  area: EntityReference[];
  areaEmit: EntityReference;
  creationDate: Date;
  document: string;
  propietario: string;
  status: string;
  all: boolean;
};

const policiesProperties: PropertiesOrBuilder<Policies> = {
  creationDate: {
    dataType: "timestamp",
    disabled: {
      hidden: true,
    },
    validation: {
      required: true,
    },
  },
  name: {
    title: "Nombre",
    validation: { required: true },
    dataType: "string",
    columnWidth: 250,
  },
  areaEmit: buildProperty({
    title: "Area que emite",
    dataType: "reference",
    description: "Selecciona el area que emite la informacion",
    path: "areas-company",
    previewProperties: ["name"],
  }),
  propietario: {
    title: "Propietario",
    validation: { required: true },
    dataType: "string",
    columnWidth: 250,
  },
  area: buildProperty({
    title: "Area",
    dataType: "array",
    of: {
      dataType: "reference",
      description: "Selecciona las areas que veran esta politica",
      path: "areas-company",
      previewProperties: ["name"],
    },
  }),
  all: {
    title: "Visible para todos",
    dataType: "boolean",
    columnWidth: 250,
  },
  document: ({ values }) =>
    buildProperty({
      title: "Doumento",
      dataType: "string",
      config: {
        storageMeta: {
          storagePath: "policies-documents/" + values.name,
          acceptedFiles: ["application/*"],
          storeUrl: true,
        },
      },
    }),
  status: {
    title: "Status",
    validation: { required: true },
    dataType: "string",
    config: {
      enumValues: {
        new: "Nuevo",
        inReview: "En revisión",
        approved: "Aprobado",
      },
    },
  },
};

export const policiesSchema = buildSchema<Policies>({
  name: "Politicas y procedimientos",
  defaultValues: {
    creationDate: new Date(),
    status: "new",
  },
  properties: policiesProperties,
});

export const buildPolicies = buildCollection({
  path: "policies-documents",
  defaultSize: "xs",
  schema: policiesSchema,
  name: "Politicas y procedimientos",
  inlineEditing: false,
  group: "Publicaciones",
  exportable: false,
  pagination: true,
  permissions: ({ authController }) => ({
    edit:
      authController.extra.roles.includes("corporate_branch_dms_gender:") ||
      authController.extra.roles.includes("controller") ||
      authController.extra.roles.includes("admin"),
    create:
      authController.extra.roles.includes("corporate_branch_dms_gender:") ||
      authController.extra.roles.includes("controller") ||
      authController.extra.roles.includes("admin"),
    delete:
      authController.extra.roles.includes("corporate_branch_dms_gender:") ||
      authController.extra.roles.includes("controller") ||
      authController.extra.roles.includes("admin"),
  }),
});
