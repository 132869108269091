import {
  buildCollection,
  buildProperty,
  buildSchema,
  EntityReference,
  PropertiesOrBuilder,
} from "@camberi/firecms";

export type Links = {
  url: string;
  name: string;
  description: string;
  img: string;
  jobs?: EntityReference[];
  all: boolean;
};

const linksProperties: PropertiesOrBuilder<Links> = {
  name: {
    title: "Nombre del sitio",
    validation: { required: true },
    dataType: "string",
    columnWidth: 250,
  },

  url: {
    title: "Link directo al sitio",
    validation: { required: true },
    dataType: "string",
    columnWidth: 250,
  },
  description: {
    title: "Descripcion del sitio",
    validation: { required: false },
    dataType: "string",
    config: {
      multiline: true,
      markdown: true,
    },
  },
  jobs: {
    title: "Puestos",
    dataType: "array",
    description:
      "Selecciona los puestos para quienes es visible esta informacion",
    of: {
      dataType: "reference",

      path: "jobs-company",
      previewProperties: ["name"],
    },
  },
  all: {
    title: "Link visible para todos",
    dataType: "boolean",
    columnWidth: 250,
  },
  img: ({ values }) =>
    buildProperty({
      title: "Logo",
      validation: { required: false },
      dataType: "string",
      config: {
        storageMeta: {
          mediaType: "image",
          storagePath: "links/" + values.name,
          acceptedFiles: ["image/*"],
          storeUrl: true,
        },
      },
    }),
};

export const linksSchema = buildSchema<Links>({
  name: "Directorio de links",
  properties: linksProperties,
});

export const buildLinks = buildCollection({
  path: "links-directory",
  defaultSize: "xs",
  schema: linksSchema,
  name: "Directorio de links",
  inlineEditing: false,
  group: "Información",
  exportable: false,
  pagination: true,
  permissions: ({ authController }) => ({
    edit:
      authController.extra.roles.includes("admin") ||
      authController.extra.roles.includes("rrhh"),
    create:
      authController.extra.roles.includes("admin") ||
      authController.extra.roles.includes("rrhh"),
    delete:
      authController.extra.roles.includes("admin") ||
      authController.extra.roles.includes("rrhh"),
  }),
});
